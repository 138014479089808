<template>
  <div class="content">
    <PageHeader :title="''" />
    <div class="page-content container-fluid support-page">
      <div class="row">
        <div
          class="col-sm-12 col-md-12 col-size-support"
          :class="{ 'col-lg-8': whitelabel.is_main }"
        >
          <div class="card">
            <div class="card-header" style="border-bottom: 1px solid #fff">
              <div class="w-100">
                <h3 class="card-title m-0 to-left">Seus chamados</h3>
                <div class="to-right opts_card" style="margin-left: 10px">
                  <!-- Ordenar Por -->
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <span class="material-icons title"> swap_vert </span
                      ><span class="sr-only">Search</span>
                    </template>
                    <div class="card">
                      <div
                        class="card-header"
                        style="display: flex; align-items: center"
                      >
                        {{ $t('generic-str.sort-by') }}
                        <b-dropdown-form class="close-btn">
                          <b-dropdown-item-button @click="closeDropdown"
                            ><span class="material-icons close-icon">
                              close
                            </span>
                          </b-dropdown-item-button>
                        </b-dropdown-form>
                      </div>
                      <div class="card-body">
                        <ul class="list-unstyled">
                          <li>
                            <div class="control-group opt-2">
                              <label class="control control-checkbox">
                                <input type="checkbox" />
                                <div class="control_indicator"></div>
                                <span>Aberto</span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <label class="control control-checkbox">
                                <input type="checkbox" />
                                <div class="control_indicator"></div>
                                <span>Fechado</span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <label class="control control-checkbox">
                                <input type="checkbox" />
                                <div class="control_indicator"></div>
                                <span>Arquivado</span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-dropdown>
                  <!--/Ordenar Por -->
                  <router-link to="/support/new-ticket">
                    <img
                      src="assets/img/icons/icon_plus.png"
                      style="width: 37px; height: 37px"
                    />
                  </router-link>
                </div>
                <div class="input-container to-right">
                  <form class="row" style="margin-right: 0">
                    <input
                      type="text"
                      placeholder="Buscar"
                      class="form-control"
                      v-model="form.subject.lk"
                    />
                    <img
                      src="assets/img/icons/icon_search.png"
                      class="c-pointer"
                      @click="fetch(1)"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div class="card-header hide">
              <form class="row" _lpchecked="1">
                <div class="col-md-4">
                  <label class="sr-only" for="inlineFormInputName2">{{
                    $t('generic-str.search-by.default')
                  }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Assunto..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      v-model="form.subject.lk"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary btn-icon"
                        type="button"
                        @click="fetch(1)"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label class="sr-only" for="status">Status</label>
                  <select
                    class="form-control"
                    id="status"
                    v-model="form.status"
                    @change="fetch(1)"
                  >
                    <option selected value>Status</option>
                    <option value="open">
                      {{ $t('generic-str.status.lbl-open') }}
                    </option>
                    <option value="closed">
                      {{ $t('generic-str.status.lbl-closed') }}
                    </option>
                    <option value="archived">
                      {{ $t('generic-str.status.lbl-filed') }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6">
                  <router-link
                    to="/support/new-ticket"
                    class="btn btn-success pull-right"
                  >
                    {{ $t('generic-str.lbl-new-call') }}
                  </router-link>
                </div>
              </form>
            </div>
            <div class="card-body block-el p-0">
              <div class="table-responsive responsive-table">
                <table v-if="fetched && tickets.length" class="table">
                  <thead>
                    <tr>
                      <th class="th-monitor" scope="col">ID</th>
                      <th class="th-monitor" scope="col">
                        {{ $t('generic-str.subject') }}
                      </th>
                      <th class="th-monitor" scope="col">
                        {{ $t('generic-str.date') }}
                      </th>
                      <th class="th-monitor" scope="col">
                        {{ $t('calls-component.last-interaction') }}
                      </th>
                      <th class="th-monitor" scope="col">Status</th>
                      <th class="th-monitor" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ticket in tickets" :key="ticket.id">
                      <td class="td-monitor">{{ ticket.id | minifyUuid }}</td>
                      <td class="td-monitor">{{ ticket.subject }}</td>
                      <td class="td-monitor">
                        {{ ticket.created_at | formatDate }}
                      </td>
                      <td class="td-monitor">
                        <img
                          src="assets/img/icons/support_refresh.png"
                          style="width: 20px; height: 26px"
                        />
                        {{
                          ticket.last_reply.type == 'staff'
                            ? 'Suporte'
                            : ticket.last_reply.user.name
                        }}
                        ({{ ticket.updated_at | relativeDate }})
                      </td>
                      <td class="td-monitor">
                        <!-- <img v-tooltip.top="'Aberto'" v-if="ticket.status == 'open'" src="assets/img/icons/support_check.png" style="width: 30px; height: 30px;"/> -->
                        <!-- <img v-tooltip.top="'Fechado'" v-if="ticket.status == 'closed'" src="assets/img/icons/support_closed.png" style="width: 30px; height: 30px;"/> -->
                        <span
                          v-tooltip.top="'Aberto'"
                          v-if="ticket.status == 'open'"
                          class="material-symbols-outlined icon-success"
                          >task_alt</span
                        >
                        <span
                          v-tooltip.top="'Fechado'"
                          v-if="ticket.status == 'closed'"
                          class="material-symbols-outlined icon-failed"
                          >unpublished</span
                        >
                        <img
                          v-tooltip.top="'Arquivado'"
                          v-if="ticket.status == 'archived'"
                          src="assets/img/icons/support_arquive.png"
                          style="width: 30px; height: 30px"
                        />
                      </td>
                      <td class="td-monitor">
                        <router-link
                          class="btn btn-yup-purple-outline"
                          :to="`support/${ticket.id}`"
                          >VER DETALHES</router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else-if="fetched" class="text-center table-placeholder">
                  <i class="fas fa-life-ring font-size-80"></i>
                  <h5 class="card-title m-t-20">
                    {{ $t('ticket-component.none') }}
                  </h5>
                  <p>
                    <a
                      href="/support/new-ticket"
                      class="btn btn-success"
                      aria-expanded="false"
                    >
                      {{ $t('generic-str.lbl-open-ticket') }}
                    </a>
                  </p>
                </div>
                <div class="loading min-h-300" v-else>
                  <LoadingAnim />
                </div>
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="whitelabel.is_main"
          class="col-sm-12 col-md-12 col-lg-4 col-size-support"
        >
          <div class="card">
            <div class="card-header">
              <h3 class="card-title m-0 to-left">Outras informações</h3>
            </div>
            <div class="card-body block-el">
              <div class="list-left-icons">
                <div class="left-icon-img">
                  <img
                    src="assets/img/icons/code_icon.svg"
                    style="width: 25px; height: 25px"
                  />
                </div>
                <div class="left-text-p">
                  <span
                    ><a href="https://doc.yup.chat/" target="_blank"
                      >Documentação da API</a
                    ></span
                  >
                </div>
                <div style="clear: both"></div>
              </div>
              <div class="list-left-icons">
                <div class="left-icon-img">
                  <img
                    src="assets/img/icons/report_icon.svg"
                    style="width: 25px; height: 25px"
                  />
                </div>
                <div class="left-text-p">
                  <span
                    ><a href="https://yup.chat/faq/" target="_blank"
                      >Central de conhecimento</a
                    ></span
                  >
                </div>
                <div style="clear: both"></div>
              </div>
              <div class="list-left-icons">
                <div class="left-icon-img">
                  <img
                    src="assets/img/icons/verified_icon.svg"
                    style="width: 25px; height: 25px"
                  />
                </div>
                <div class="left-text-p">
                  <span
                    ><a
                      href="https://yup.chat/politica-de-privacidade/"
                      target="_blank"
                      >Politica de Privacidade</a
                    ></span
                  >
                </div>
                <div style="clear: both"></div>
              </div>
              <div class="list-left-icons">
                <div class="left-icon-img">
                  <img
                    src="assets/img/icons/verified_icon.svg"
                    style="width: 25px; height: 25px"
                  />
                </div>
                <div class="left-text-p">
                  <span
                    ><a
                      href="https://yup.chat/area-comercial/termos-condicoes/"
                      target="_blank"
                      >Termos e Condições</a
                    ></span
                  >
                </div>
                <div style="clear: both"></div>
              </div>
              <div class="list-left-icons">
                <div class="left-icon-img">
                  <img
                    src="assets/img/icons/verified_icon.svg"
                    style="width: 25px; height: 25px"
                  />
                </div>
                <div class="left-text-p">
                  <span
                    ><a
                      href="https://yup.chat/politica-de-cookies/"
                      target="_blank"
                      >Política de cookies</a
                    ></span
                  >
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketService from '@/services/ticket.service';
import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
    LoadingAnim,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: false,
      tickets: [],
      form: {
        subject: {},
        status: '',
        sort: 'created_at:desc',
        page: 1,
      },
      pages: 1,
      exporting: false,
      isSending: false,
      opens: [],
      opensPercent: 0,
      answeredPercent: 0,
      answered: [],
    };
  },
  computed: {
    isMainWhitelabel() {
      return this.$store.state.admin.whitelabel.account.whitelabel_id === null;
    },
    whitelabel() {
      return this.$store.state.admin.whitelabel;
    },
    info() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      TicketService.getTickets(this.form).then(
        (response) => {
          this.fetched = true;
          this.tickets = response.data;
          this.opens = [];
          this.answered = [];
          // this.pages = response.last_page;
          if (this.tickets.length > 0) {
            this.tickets.forEach((element) => {
              if (element.status === 'open') {
                this.opens.push(element);
              }

              if (element.status === 'answered') {
                this.answered.push(element);
              }
            });
            this.opensPercent = (this.opens.length / this.tickets.length) * 100;
            this.answeredPercent =
              (this.answered.length / this.tickets.length) * 100;
          }
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
td a i {
  font-size: 1.19rem;
}
.card-title {
  font-size: 26px !important;
  color: var(--foreground);
}
.m-0 {
  margin: 0px !important;
}
.w-100 {
  width: 100% !important;
}
.to-left {
  float: left !important;
}
.input-container {
  float: right;
  position: relative;
  width: 40%;
}
.input-container img {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  margin-top: 10px;
  margin-right: 8px;
  cursor: pointer;
}
.to-right {
  float: right !important;
}
.table td,
.table th,
.table thead th {
  border-bottom: 1px solid #fff;
}
.th-monitor {
  color: var(--gray-font-color);
  font-size: 14px !important;
  border: #fff 1px solid !important;
  padding-top: 30px;
  padding-bottom: 30px;
  border-color: var(--table-border) !important;
}
.td-monitor {
  border: 1px solid #fff !important;
  border-color: var(--table-border) !important;
  font-weight: 300;
  color: var(--gray-font-color-2);
  vertical-align: middle;
}
.btn-outline-rebrand {
  background: #fff;
  border: solid 1px #752de6;
  font-size: 14px !important;
  color: #752de6;
  padding: 10px;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  width: 166px;
  height: 40px;
  display: block;
  text-align: center;
}
.btn-outline-rebrand:hover {
  background: #f7f3ff;
}
.left-text-p {
  color: var(--foreground);
  font-size: 16px;
  float: left;
  margin-left: 10px;
}
.left-text-p a {
  color: var(--foreground);
}
.left-icon-img {
  float: left;
}
.list-left-icons {
  margin-bottom: 40px;
}
@media (max-width: 1081px) {
  .col-size-support {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}
@media (max-width: 706px) {
  .input-container {
    float: left !important;
    width: 100%;
    margin-top: 12px;
  }
}

.support-page .row .card {
  height: calc(100% - 1.875rem);
}

.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}
</style>
